import Cookies from "js-cookie";
import { useState } from "react";
import Swal from "sweetalert2";

const CookiesBanner = () => {
    const [showBanner, setShowBanner] = useState(Cookies.get('cookiesStatus') === undefined);
    const [showSetting, setShowSetting] = useState(false);
    const [custCookies, setCustCookies] = useState<string[]>([]);

    const setCookieStatus = (status: string) => {
        Cookies.set('cookiesStatus', status, { expires: 1 });
        setShowBanner(false);
        setShowSetting(false);
    };

    const acceptCookies = () => setCookieStatus('accept-all');
    const strictCookies = () => setCookieStatus('strictly-necessary');

    const confirmCookies = () => {
        if (custCookies.length > 0) {
            Cookies.set('cookiesStatus', custCookies.join(','), { expires: 1 });
            setShowBanner(false);
            setShowSetting(false);
        } else {
            Swal.fire("No choice has been made. Please select at least one.");
        }
    };

    const handleCustCookies = (e: any) => {
        setCustCookies((prevCookies) => {
            let updatedCookies = [...prevCookies];
            if (e.target.checked) {
                updatedCookies.push(e.target.value);
            } else {
                updatedCookies = updatedCookies.filter((cookie) => cookie !== e.target.value);
            }
            return updatedCookies;
        });
    };

    return (
        <>
            {showSetting && (
                <div className="cookiesSetting">
                    <div className="d-flex container">
                        <div className="row w-100">
                            <div className="col-10">
                                <h4>Privacy Settings Hub</h4>
                            </div>
                            <div className="col-2">
                                <button className="closeCookies cookiesSettings" onClick={() => setShowSetting(false)} aria-label="Cookies Settings">X</button>
                            </div>
                            <div className="scrollY">
                                <div className="row w-100">
                                    <div className="col-12">
                                        <p>Upon visiting any website, there is a possibility that it will store or retrieve data on your browser...</p>
                                    </div>
                                    <div className="col-12">
                                        <button className="custCookies cookiesAccept fL" onClick={acceptCookies} aria-label="Accept cookies">Accept All</button>
                                    </div>
                                    {/* Cookie settings sections */}
                                    {/* Add your sections here */}
                                    <div className="col-12">
                                        <button className="custCookies cookiesSettings btnBorder" onClick={confirmCookies} aria-label="Cookies Settings">Confirm My Choices</button>
                                        <button className="custCookies cookiesReject btnBorder" onClick={strictCookies} aria-label="Reject cookies">Strictly Necessary</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showBanner && (
                <div className="divCookies" id="cookiesPrompt">
                    <div className="d-flex container">
                        <div className="row w-100">
                            <div className="col-6">
                                This website uses different types of cookies to enable, improve and monitor the use of our website...
                            </div>
                            <div className="col-6">
                                <button className="custCookies cookiesAccept" onClick={acceptCookies} id="rcc-confirm-button" aria-label="Accept cookies">Accept All Cookies</button>
                                <button className="custCookies cookiesReject" onClick={strictCookies} id="rcc-reject-button" aria-label="Reject cookies">Strictly Necessary</button>
                                <button className="custCookies cookiesSettings" onClick={() => setShowSetting(true)} id="rcc-settings-button" aria-label="Cookies Settings">Cookies Settings</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default CookiesBanner;