import axios from "axios";
import { TARGET_API, buildURL, fetchIpAddress } from "../../api/utils";
import React, { useEffect, useState } from 'react'
import bg from "../../assets/images/background1-min.png";
import Footer from "../../component/Layout/NonLoggedInFooter";
import Header from "../../component/Layout/NonLoggedInHeader";
import { NavLink , useNavigate, useLocation} from 'react-router-dom';
import Cookies from "js-cookie";

let deviceType= '';
let browserType = '';
function ManagePanelSurveyResponse(props) {
    const location = useLocation()
    const queryString = new URLSearchParams(location.search)
    const navigate = useNavigate();
    const [ip, setIP] = useState()
    const [errorMessage, setErrorMessage] = useState("Please wait while you're being redirected...")

    useEffect(() => {
        getIP()
        DeviceType()
        BrowserDetail()
    },[])

    const getIP = async () => {
      const resIp = await fetchIpAddress();
      setIP(resIp);
    }

    const DeviceType = async function(){
        let dType = "desktop";
        const userDevice = navigator.userAgent;
            if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userDevice)) {
              dType = "tablet";
            }
            if (
              /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                userDevice
              )
            ) {
              dType = "mobile";
            }
            deviceType = dType;
    }
  
    const BrowserDetail = async function () {
      // return window.navigator.userAgent;
      var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
  
      if(test(/edg/i)){
        browserType = 'Microsoft Edge';
      }else if(test(/trident/i)){
        browserType = 'Microsoft Internet Explorer';
      }else if(test(/firefox|fxios/i)){
        browserType = 'Mozilla Firefox'
      }else if(test(/opr\//i)){
        browserType = 'Opera'
      }else if(test(/ucbrowser/i)){
        browserType = 'UC Browser'
      }else if(test(/samsungbrowser/i)){
        browserType = 'Samsung Browser'
      }else if(test(/chrome|chromium|crios/i)){
        browserType = 'Google Chrome'
      }else if(test(/safari/i)){
        browserType = 'Apple Safari'
      }else {
        browserType = 'Other'
      }
    }

    useEffect(() => {
        managePanelSurveyResponse();
    },[])

    const managePanelSurveyResponse = async () => {
        const token = Cookies.get("token");
        let status = queryString.get('status')
        let identifier = queryString.get('identifier')
        if(token !== undefined){
          let data = {
            status: status,
            identifier: identifier,
            ipv4: ip,
            ipv6: null,
            country: null,
            deviceType: deviceType,
          }

          const url = "/survey/manage-panel-survey-response/";

          const response = await axios.post(buildURL(url, TARGET_API.BASE), data, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });

          if(response.data.status === 1){
              let strMsg = response.data?.message;
              switch(status){
                case 'c':
                strMsg = 'You have completed the survey successfully.';
                break;
                case 't':
                strMsg = 'Thanks for your time but you aren\'t eligible for this survey.';
                break;
                case 'q':
                strMsg = 'Thanks for your time but this survey has achieved it\'s quota.';
                break;
                case 'qc':
                strMsg = 'Thanks for your time but it seems that you haven\'t completed the survey properly.';
                break;
                case 'd':
                strMsg = 'Thanks for your time but the survey is closed.';
                break;
              }
              setErrorMessage(strMsg);
          }else{
            setErrorMessage(response.data?.message ? response.data?.message : response?.message);
          }
        } else {
          setErrorMessage("Thanks for Participation");
        }
    }
  return (<>
    <div className="page-wrapper">
        <Header pageName="thanks" />
        <section className="course-one__top-title cSecOne" style={{ backgroundRepeat: "no-repeat", backgroundImage: `url(${bg})`, height: "550px" }}>
          <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <br /><br />
                        <h3 className="banner-one__title banner-one__light-color updatedFont">
                        <span className="whit">THANK</span>
                        <span className="orng">YOU</span>
                        </h3>
                        <hr className="orng cw50p" />
                        <p><span className="smHeading txtUC">We care about your privacy</span></p>
                        <p className="smWhitTxt cw95p">
                        {errorMessage}
                        </p>
                        {
                        (
                          () => {
                              const token = Cookies.get("token");
                              if(token){
                                return(
                                  <>
                                    <br /><br />
                                    <NavLink
                                      to="/dashboard"
                                      className="btnOrngGrd"
                                      style={{ color: "#FFF", fontWeight: 700 }}
                                    >
                                      Go To Dashboard
                                    </NavLink>
                                  </>
                                )
                              } else {
                                <>
                                  <br /><br />
                                  <NavLink
                                    to="/login"
                                    className="btnOrngGrd"
                                  >
                                  Login
                                  </NavLink>
                                </>
                              }
                            }
                          )()
                        }
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
  </>)
}

export default ManagePanelSurveyResponse

