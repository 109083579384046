import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import AppleLogin from 'react-apple-login';
import { useDispatch } from "react-redux";
import { JwtPayload, jwtDecode } from 'jwt-decode';
import {
  PersonalInfo,
} from "../../model/user";
import { setLoginDetails } from "../../store/profile/profileSlice";
import {
  saveUserInfo,
  getSocialUser,
} from "../../api";
import { setAuthToken } from "../../store/auth/authSlice";
import Cookies from "js-cookie";
import { platform } from "os";
type googleoginDataType ={
  strToken: string;
  platform: string;
  source: string;
}
type ChildComponentProps = {
  callShowConsentForm?: (val: boolean, loginMethod: string, loginData:googleoginDataType) => void;
};

const SocialLogin : React.FC<ChildComponentProps> = ({ callShowConsentForm }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [token, setToken] = useState<string>("");
  const clientId = '914159439743-j524ke13uj5u3ktuac7ktur5bh1p775g.apps.googleusercontent.com';
  return (
        <>
          <div className="row">
            <div className="col-6" style={{justifyContent: "center"}}>
              <GoogleOAuthProvider clientId={clientId}>
                <GoogleLogin 
                  text="continue_with"
                  size="medium"
                  onSuccess={credentialResponse => {
                    let strToken = credentialResponse.credential ? credentialResponse.credential : '';
                    let platform = "Google";
                    let source = "web";
                    getSocialUser(strToken, platform, source, false)
                    .then((resp) => {
                      if (!isNaN(resp?.status) && parseInt(resp?.status) === 1) {

                        const token = resp?.payload?.token;
                        dispatch(setAuthToken(token));
                        Cookies.set("token", token, { expires: 1, });
                        
                        if(resp?.payload.isGeoInfoCompleted && resp?.payload.isRegistrationCompleted)
                        navigate("/dashboard");
                        else {
                          var googleUserInfo = jwtDecode<PersonalInfo>(strToken);
                          var password = '';
                          var email = googleUserInfo ? googleUserInfo?.email : '';
                          
                          dispatch(
                            setLoginDetails({
                              email: email,
                            })
                          );
                          
                          const personalInfo: PersonalInfo = {
                            password,
                            email,
                          };

                          saveUserInfo(personalInfo, token).then(
                            (resp) => {
                              if (resp?.status === 1) {
                                dispatch(setLoginDetails(resp?.payload));                              
                                navigate("/userprofile");                              
                              } else {
                                Swal.fire(resp?.message);
                              }
                            }
                          );
                        }
                      }
                      if (parseInt(resp?.status) === 0) {
                        if(resp?.messageCode == "CONSENT_REQUIRED"){
                          if(callShowConsentForm)
                          callShowConsentForm(true,'google',{strToken, platform, source});
                        }else{
                        Swal.fire(resp?.message);
                        }
                      }
                    })
                    .catch((err) => {
                      Swal.fire(err?.message);
                    });
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                />
              </GoogleOAuthProvider>
            </div>
            <div className="col-6">
              {/* <button className="btnSL slApple"  onClick={() => {
                Swal.fire('Invalid Apple developer credentials');
              }}>Continue with Apple</button> */}
              <AppleLogin
                clientId="com.gotosample.auth.client"
                redirectURI="https://stage.gotosample.com/get-started/"
                usePopup={true}
                callback={appleResponse => {
                  if (!appleResponse.error) {
                    console.log(appleResponse);
                  }
                }} // Catch the response
                scope="email name"
                responseMode="query"
                render={renderProps => (  //Custom Apple Sign in Button
                <button
                  onClick={renderProps.onClick}
                  className="btnSL slApple">
                      Continue with Apple
                </button>
                )}
  />
            </div>
          </div>
      </>
    );
}
export default SocialLogin;