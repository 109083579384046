import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { TARGET_API, buildURL, fetchIpAddress } from '../../api/utils';
import { postAPI } from '../../ApiService';
import { relevantClientId } from '../../constants/relevantID.keys';

// Declare the type for the global `window.relevantID` object
declare global {
  interface Window {
    relevantID: any; // Replace `any` with a more specific type if available
  }
}

const SurveyStart = () => {
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    localStorage.removeItem('surveyStartIP');

    const loadScript = (src: string) => {
      return new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.type = 'text/javascript';
        script.async = true;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script ${src}`));
        document.body.appendChild(script);
      });
    };

    const getQueryStringParam = (param: string) => {
      const queryStringArray = window.location.search.substring(1).split('&');
      for (const item of queryStringArray) {
        const [key, value] = item.split('=');
        if (key.toLowerCase() === param.toLowerCase()) {
          return value;
        }
      }
      return '';
    };

    const callRVIDService = () => {
      // console.log('RelevantID service called');
      const clientID = relevantClientId;
      const objectData = {
        ClientID: clientID,
        SurveyID: getQueryStringParam('mfid'),
        PanelistID: getQueryStringParam('identifier'),
        GeoCodes: '1,US'
      };
      if (window.relevantID) {
        window.relevantID.callService(objectData, async (responseData: any) => {
          const detectDeviceType = async () => {
            if ("Capture" in responseData) {
              const deviceTypeFromCapture = responseData.Capture.devicetype;
              if (deviceTypeFromCapture === '1') {
                return "mobile";
              } else if (deviceTypeFromCapture === '2') {
                return "tablet";
              } else {
                return "desktop";
              }
            } else {
              // Fallback to device type based on user agent
              try {
                const userAgent = navigator.userAgent;
                if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
                  return "tablet";
                } else if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent)) {
                  return "mobile";
                } else {
                  return "desktop";
                }
              } catch (error) {
                console.error('Error detecting device type:', error);
                return 'desktop'; // Default to 'desktop' if detection fails
              }
            }
          };
        
          const detectBrowserType = async () => {
            if ("Capture" in responseData) {
              const browserFromCapture = responseData.Capture.browser;
              return browserFromCapture || 'Other';
            } else {
              // Fallback to browser detection based on user agent
              try {
                const userAgent = window.navigator.userAgent;
                const browserMapping: { [key: string]: string } = {
                  edg: 'Microsoft Edge',
                  trident: 'Microsoft Internet Explorer',
                  firefox: 'Mozilla Firefox',
                  opr: 'Opera',
                  ucbrowser: 'UC Browser',
                  samsungbrowser: 'Samsung Browser',
                  chrome: 'Google Chrome',
                  safari: 'Apple Safari'
                };
        
                const detectedBrowser = Object.keys(browserMapping).find((key) => new RegExp(key, 'i').test(userAgent));
                return detectedBrowser ? browserMapping[detectedBrowser] : 'Other';
              } catch (error) {
                console.error('Error detecting browser type:', error);
                return 'Other';
              }
            }
          };

          const startSurvey = async () => {
            const getClientIP = async () => {
              if ('ClientIP' in responseData && responseData.ClientIP !== null) {
                return responseData.ClientIP;
              } else {
                try {
                  return await fetchIpAddress();
                } catch (error) {
                  console.error('Failed to get client IP:', error);
                  return null;
                }
              }
            };

            const mfID = queryString.get('mfid');
            const identifier = queryString.get('identifier');
            const ip = await getClientIP();
            const token = Cookies.get('token');
            const data = {
              mfID,
              identifier,
              ipv4: ip,
              start: false,
              ipv6: null,
              country: responseData?.Country || null,
              deviceType: await detectDeviceType(),
              browserName: await detectBrowserType(),
              security: responseData
            };
        
            if (token) {
              const url = '/survey/start-survey/';
              try {
                await axios.post(buildURL(url, TARGET_API.BASE), data, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });
              } catch (error) {
                console.error('Failed to start survey:', error);
              }
            }
        
            try {
              const res = await postAPI('/survey/start', data);
              if (res.status === 1) {
                if (res.payload.preScreen) {
                  localStorage.setItem('surveyStartIP', ip || '');
                  window.open(res.payload.preScreenRedirectUrl, '_self');
                } else {
                  window.open(res.payload.surveyLink, '_self');
                }
              } else {
                if (res.messageCode === 'SURVEY_REDIRECTED' || res.messageCode === 'SURVEY_DISABLED') {
                  setErrorMessage(res.message);
                  setTimeout(() => {
                    window.open(res.payload.supplierUrl, '_self');
                  }, 3000);
                } else {
                  setErrorMessage(res.message);
                }
              }
            } catch (error) {
              console.error('Failed to process survey response:', error);
            }
          };
          startSurvey();
        });
      } else {
        console.error('relevantID is not defined');
      }
    };

    loadScript('https://d3op16id4dloxg.cloudfront.net/RelevantID4.js')
      .then(() => {
        callRVIDService();
      })
      .catch((error) => {
        console.error('Failed to load the script', error);
      });
  }, []);

  return (
    <div>
      {errorMessage ? (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>{errorMessage}</div>
      ) : (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          Please wait while you're being redirected...
        </div>
      )}
    </div>
  );
};

export default SurveyStart;