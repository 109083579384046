import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchIpAddress } from '../../api/utils';
import { postAPI } from '../../ApiService';

const ManageSurveyResponse = () => {
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [ip, setIP] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState("Please wait while you're being redirected...");

  const getIP = useCallback(async () => {
    const resIp = await fetchIpAddress();
    setIP(resIp);
    return resIp;
  }, []);

  const getDeviceType = useCallback(() => {
    const userDevice = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userDevice)) {
      return 'tablet';
    }
    if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userDevice)) {
      return 'mobile';
    }
    return 'desktop';
  }, []);

  const getBrowserType = useCallback(() => {
    const userAgent = window.navigator.userAgent;
    if (/edg/i.test(userAgent)) return 'Microsoft Edge';
    if (/trident/i.test(userAgent)) return 'Microsoft Internet Explorer';
    if (/firefox|fxios/i.test(userAgent)) return 'Mozilla Firefox';
    if (/opr\//i.test(userAgent)) return 'Opera';
    if (/ucbrowser/i.test(userAgent)) return 'UC Browser';
    if (/samsungbrowser/i.test(userAgent)) return 'Samsung Browser';
    if (/chrome|chromium|crios/i.test(userAgent)) return 'Google Chrome';
    if (/safari/i.test(userAgent)) return 'Apple Safari';
    return 'Other';
  }, []);

  const manageSurvey = useCallback(async (ip: string, deviceType: string, browserType: string) => {
    const status = queryString.get('status');
    const identifier = queryString.get('identifier');
    const isSPID = queryString.get("isSPID");

    let data: any = {
      status: status,
      identifier: identifier,
      ipv4: ip,
      start: false,
      ipv6: null,
      country: null,
      deviceType: deviceType,
      browserType: browserType,
    };
    if (isSPID === "true") {
      data.isSPID = true;
    }

    const res = await postAPI('/survey/manage-response/', data);

    if (res.status === 1) {
      window.open(res?.payload?.supplierUrl, '_self');
    } else {
      if(res?.messageCode === 'SURVEY_REDIRECTED'){
        window.open(res?.payload?.supplierUrl, '_self');
      } else{
        setErrorMessage(res?.message)
      }
    }
  }, [queryString]);

  useEffect(() => {
    const initialize = async () => {
      const ip = await getIP();
      const deviceType = getDeviceType();
      const browserType = getBrowserType();
      manageSurvey(ip, deviceType, browserType);
    };
    initialize();
  }, [getIP, getDeviceType, getBrowserType, manageSurvey]);

  return (
    <div>
      <div style={{ textAlign: 'center', marginTop: "50px" }}>{errorMessage}</div>
    </div>
  );
};

export default ManageSurveyResponse;